.edit-enterprise-icon{
  position: absolute;
  right: 1%;
  /* top: 125px; */
  color:#2bf288;
  cursor: pointer;
  font-size: 30px !important;
  z-index: 1;
}

.delete-enterprise-icon{
  position: absolute;
  right: 1.25%;
  /* top: 175px; */
  color:#fd0000;
  cursor: pointer;
  font-size: 30px !important;
  z-index: 1;
  margin-top: 40px;
}

.edit-prepaid-icon{
  position: absolute;
  right: 1%;
  top: 125px;
  z-index: 1;
}
.customer-table-title{
  font-size: 24px;
}

.customer-action{
  display: flex;
  flex-direction: column;
}
.account-delete-bin-table {
  color:#fd0000;
  cursor: pointer;
  font-size: 17px !important;
  z-index: 1;
  /* margin-left: 260px; */
}
.account-delete-bin{
  color:#fd0000;
  cursor: pointer;
  font-size: 30px /* !important */;
  z-index: 1;
  /* margin-left: 260px; */
}

.account-enterprise-icon-table {
  color:#2bf288;
  cursor: pointer;
  font-size: 17px !important;
  z-index: 1;
}
.account-enterprise-icon{
  color:#2bf288;
  cursor: pointer;
  font-size: 30px/*  !important */;
  z-index: 1;  
}
/*TP-4921*/
.account-approve{
  color: #2bf288;
  cursor: pointer;
  font-size: 25px !important;
  z-index: 1;  
}
.account-reject{
  color:#fd0000;
  cursor: pointer;
  font-size: 25px !important;
  z-index: 1;  
}
.margin-left {
  margin-left: 40px;
  display: inline !important;
}
/*Medium devices (smartphone, 768px and up)*/
@media (max-width: 768px) {
  .customer-table-title{
    font-size: 16px;
  }
  .edit-enterprise-icon, .edit-prepaid-icon{
    font-size: 20px !important;
    top: 100px;
  }
}

.analytics-icon-div{
  position: absolute;
  margin: 78px 0px 0px -40px;
}

.analytics-icon-div svg{
  height: 30px;
  width:30px;
  color:yellow;
  cursor: pointer;
}
/*TP-5260*/
.rdt_Table,
.rdt_TableFooter{
  max-width: 98% !important;
}

/* TP-5260 */
@media (max-width: 1199px) {
.rdt_TableFooter,
.kAKLbp{
  padding-right: 40px;
}}
