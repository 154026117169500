/*************Map CSS**************/
.mapIcon {
  font-size: 16px;
  color: darkorchid;
}

.mapStyle {
  width: 45vw;
  height: 82vh;
  margin-left: 25vw;
  margin-top: -10px;
  position: fixed;
}

/*Removed Copywrite*/
.ol-attribution.ol-uncollapsible {
  display: none;
  opacity: 0;
  font-size: 5px;
}

.ol-popup {
  position: absolute;
  background-color: whitesmoke;
  -webkit-filter: drop-shadow(0 1px 4px rgba(0, 0, 0, 0.2));
  filter: drop-shadow(0 1px 4px rgba(0, 0, 0, 0.2));
  border-radius: 10px;
  padding:1px;
  border: 1px solid #cccccc;
  bottom: 12px;
  left: -50px;
  min-width: 225px;
  color: black;
  display: none;
  /*On Condition*/
}

.ol-popup:after, .ol-popup:before {
  top: 100%;
  border: solid transparent;
  /* content: " "; */
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  z-index: 1;
}

.ol-popup:after {
  border-top-color: white;
  border-width: 10px;
  left: 48px;
  margin-left: -10px;
}

.ol-popup:before {
  border-top-color: #cccccc;
  border-width: 11px;
  left: 48px;
  margin-left: -11px;
}

.mapLoad {
  width: 5rem;
  height: 5rem;
  display: none;
  /*On Condition*/
  position: absolute;
  color: blueviolet;
  margin-left: 50%;
  margin-top: 17%;
  font-size:50px;
}
.mapStyle .ol-layer canvas{
  transform: matrix(1, 0, 0, 1, 0, 0) !important;
}

/****************************/
/*Medium devices (smartphone, 768px and up)*/
@media (max-width: 992px) {
}
