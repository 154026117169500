.container {
    display: flex;
    align-items: center;
    padding: 20px;
  }
  
  .graph {
    width: 350px;
    margin: 0 20% auto;
    margin-top: 40px;
    position: relative;
  }
  
  .legends {
    width: 300px;
  }
  
  .legends li.legend {
    list-style: none;
    display: inline-flex;
    width: 50%;
  }
  
  .legends li.legend .icon {
    width: 20px;
    height: 20px;
    margin-right: 4px;
    margin-bottom: 5px;
    border-radius: 50%;
  }
  
/* .chart__legend{
  width:100px;
  display: flex;
  flex-direction: column;
}

 .legend__item--inline{
   display: inline-flex;
 } */
/* 
 .value {
  font-size: 16px;
  font-weight: 300;
  fill: #333;
}

.label {
  font-size: 18px;
  fill: #333;
}

.axis path {
    fill: none;  
  }
 .axis .tick line {
    stroke-width: 1;
    stroke: rgba(0,0,0,0.1);
    font-size: 12px;
}
.chart{
  position: relative;
}

.chart__graphic{
  display: inline-block;
}

.chart__legend{
  display: inline-block;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);  
} */

/* .legend__item{
  
  --inline{
    &>*{
      display: inline-block;
    }
    &>*+*{
      margin-left: 10px;
    }    
  }
  
} */

/* .legend__item--inline{
  display: inline-block;
  margin-left: 10px;
}

.legend__item-badge{
  width: 10px;
  height: 10px;
} */


.chart__legend{
  display: inline-block;
  position: relative;
  top: 50%;
  transform: translateY(-50%);  
}


.legend__item--inline{
  display: flex;
  /* margin-left: 10px;       */
  margin:5px
  
}
.legend__item-badge, .legend__item-text, .legend__item-value{
  margin: 2px;
}

.legend__item-badge{
  width: 10px;
  height: 10px;
  margin-top: 10px;
}