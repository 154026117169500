.group-tree-select.react-drop-down-tree-select .drop-down{
  top:0px !important;
  left: 0px !important;
}
.group-participants .ftSrFl /*Table Header*/{
  max-width: 25vw !important;
}
.group-participants .cBYLcD/*Table Row*/{
  max-width: 25vw !important;
}
/*Medium devices (smartphone, 768px and up)*/
@media (max-width: 992px) {

}
@media (max-width: 425px) {

}

.drop-down {
  position: relative;
  top: -26px;
  margin: 0 auto;
  width: 140px;
  border: 1px solid #666;
  background: #475154;
  margin-left: 116px;
  margin-bottom:-22px
}

.drop-down-text {
  color: white;
  padding: 2px 14px;
  cursor: pointer;
  height: auto;
}

.drop-down-text:hover {
 color: white;
 background: #475154;
}

.drop-down-text:after {
  content: "\2795";
  transition: all .5s;
  float: right;
}

.drop-down.active .drop-down-text:after {
  content: "\2796";
  
}

.drop-down-items {
  position: relative;
  visibility: hidden;
  opacity: 0;
  max-height: 0px;
  transition: max-height 0.6s ease;
}


.drop-down.active .drop-down-items {
  visibility: visible;
  border-top: 1px solid #666;
  height: auto;
  max-height: 290px;
  opacity: 1;
  transition: max-height 0.7s, opacity 3s, visibility 4s ease;
}

.drop-down-item {
 cursor: pointer;
 padding: 4px 15px;
}

.drop-down-item:not(:last-child) {
  border-bottom: 1px solid #666;
}


.drop-down-item:hover {
  color: white;
  background: #3a4143;
}

.fa-stack-display {
  color: white;
  height: 3em;
  vertical-align: middle;
  width: 2.5em; 
  left: 450px;
  margin-right: -18px;
  cursor: pointer;
}

.group-steam-type {
  width:100px;
  padding: 0px 10px 0px 10px;
}

.globalGroupHuntCls{
  width: 300px;
  border-radius: 8px; /*TP-5488*/
  /* margin: auto; */
  margin: 0px 100px 0px 650px;
  background-color: var(--main-text-color);
  /* border-radius: 10px; */
}

#show-online-container{
  margin: 5px 0px 0px 0px;
} 


/* Groups  table view  alignment and font size.Jira Ticket:TP-993*/
 .diAKEs {
  white-space: nowrap !important;
	text-overflow: ellipsis !important;
  padding-left:5px!important;
  font-size: 16px!important;
  line-height: normal;
  overflow: hidden !important;
  font-weight: 400;
}

.dpYJmJ{
  padding-left: 5px !important;
}

.group-col-16{
  font-size: 16px!important;
}
.disable-span-link{
  pointer-events: none;
}

#show-online-container .non-pointer{
  pointer-events: none;
}

.text-shadox {
  text-shadow: 2px 2px black;
}

.legend-cls {
  margin-top: 10px;
}

.download-csv-report {
  margin-left: calc(100% - 57px) !important;
}
.cardposition i{
  position: absolute;
  left: 40px;
  padding: 10px;
  color: #ffffff !important;
  z-index: 100;
}