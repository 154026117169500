.switch-icons {
    font-size: 40px !important;
    cursor: pointer;
    display: inline-block;
}

.left-icon {
    padding-top: 5px;
}

.right-icon {
    padding-top: 5px;
}