:root{
  --main-text-color:white;
  --main-bg-color:#1c2238;
}

body {
  font-family: Titillium Web !important;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  color: var(--main-text-color);
}

a {
  color: inherit;
  text-decoration: inherit;
  cursor: pointer;
  display: inherit;
}

a:active, a:hover {
  color: inherit;
  display: inherit;
  text-decoration: inherit;
}

input[type=image]:disabled {
  opacity: 0.5;
}

canvas {
  width: inherit;
}

option:not(:checked) {
  background-color: #1c2238;
  color: white;
}

option:checked {
  background-color: white;
  color: #1c2238;
}
/********************************Table***************************************/

.rdt_Table {
  border-collapse: initial !important;
  border-spacing: 1px !important;
  width: 100% !important;
  /* overflow-y: scroll; */
  background-color: #384571 !important;
  border-radius: 3px !important;
}

.rdt_TableRow {
  color: inherit !important;
  text-align: left !important;
  padding: 0px !important;
  word-wrap: inherit !important;
  border: 1px solid #1c2238;
}

.rdt_TableHeadRow {
  background-color: #7452cb !important;
  font-size: 14px !important;
  color: inherit !important;
  border-radius: 3px !important;
  border-bottom: 0px solid #1c2238 !important;
  border-left: 1px solid #1c2238 !important;
  border-right: 1px solid #1c2238 !important;
  min-height: 15px !important;
}
#custDtlsBtn{
	word-wrap: anywhere !important;
}
.rdt_TableCol, .rdt_TableCell{
  border-bottom: 1px solid #1c2238 !important;
  border-left: 1px solid #1c2238 !important;
  border-right: 1px solid #1c2238 !important;
}
.rdt_TableRow:nth-child(2n)/*, tr:nth-child(2n)*/ {
  background-color: #2d3858 !important;
  border-radius: 3px !important;
}

.rdt_TableHeader {
  padding: 0px !important;
  min-height: 10px !important;
}

.ksKbuS {
  color: inherit !important;
  border-radius: 3px !important;
  border-spacing: 1px !important;
  font-size: 24px !important;
}

.ffiUQl {
  color: inherit !important;
  font-size: 16px !important;
  font-weight: bold !important;
  background-color: #7452cb !important;
  border-radius: 3px !important;
  cursor: alias !important;
  border-spacing: 1px !important;
}

.iMLKlc {
  color: inherit !important;
  font-size: 16px !important;
  border-radius: 3px !important;
  cursor: pointer !important;
  border-spacing: 1px !important;
  word-wrap: anywhere !important;
  line-height: 24px !important;
}

.iMLKlc div:first-child {
  white-space: inherit !important;
}

.cFiWpo, .fJRgfo, .hNRBLd, .hElmdu, .sc-jTzLTM {
  font-size: 20px !important;
  color: inherit !important;
  background-color: inherit !important;
  font-weight: bold !important;
}

.fJRgfo, .iZMbPK {
  display: none !important;
  /*not Required to show*/
}

.dQKnoF {
  background-color: #384377 !important;
  border-radius: 0 !important;
  padding: 3px !important;
  height: auto !important;
  width: auto !important;
}

.dQKnoF svg {
  fill: whitesmoke;
  vertical-align: sub !important;
}

.dQKnoF:hover:not(:disabled) {
  background-color: #724ed3 !important;
}

.kJdJTV {
  overflow-y: hidden !important;
  scrollbar-color: #2bf289 #1c2238 !important;
}

.JzHiH{
  min-height: 35px !important;
}
.JzHiH:not(:first-child){
  border-top-width: 0px !important;
}
.cBYLcD{
  font-size: 16px !important;
  padding-right: 0px !important;
  padding-left: 5px !important;
  min-width: 10vw !important;
  max-width: 16vw !important;
}

.egxUDH{
  font-size: 16px !important;
  padding-right: 0px !important;
  padding-left: 5px !important;
}

.ftSrFl{
  padding: 0px 0px 2px 5px !important;
  min-width: 10vw !important;
  max-width: 18vw !important;
}
.cBYLcD div:first-child {
	white-space: nowrap !important;
	text-overflow: ellipsis !important;
}

/*Paggination was not visible on safari*/
.eNvZbY{
  height: auto !important;
}

.sc-gqjmRU{
  font-size: 18px;
  position: absolute;
  right: 0;
}

.rdt_TableHeadRow {
    background-color: #7452cb !important;
    font-size: 14px !important;
    color: inherit !important;
    border-radius: 3px !important;
    border-bottom: 0px solid #1c2238 !important;
    border-left: 1px solid #1c2238 !important;
    border-right: 1px solid #1c2238 !important;
    min-height: 45px !important;
}

.kFWwiD{
  position: relative;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  line-height: normal;
  padding-left: calc(48px / 6);
  padding-right: calc(48px / 6);
  -webkit-box-align: center;
  -webkit-box-flex: 1;
  flex-grow: 1;
  flex-shrink: 0;
  min-width: 160px;
  max-width: 160px;
  font-size: 16px !important;
  font-weight: 400;
}

/**************************************************/

/*For Chrome*/
::-webkit-scrollbar {
  background-color: transparent;
  width: 10px;
  height: 10px;
  display: none !important;
}
::-webkit-scrollbar-track {
  background-color: transparent;
}
::-webkit-scrollbar-track-piece {
  background-color: transparent;
}
::-webkit-scrollbar-thumb {
  background-color: white;
}
::-webkit-scrollbar-corner {
  background-color: transparent;
}
/*From here below Custom CSS*/
.home {
  /* min-height: 100vh; */
  /* background-image: url(care4dImg/Twilight_Scene.png), url(care4dImg/sky_gradient.png); */
  /* background-image: url(care4dImg/Swirl-Background-Tablet-V2.gif); */
  /* background-size: contain, cover; */
  /* background-repeat: no-repeat; */
  /* background-position: 50%; */
  min-height: 100vh;
  background-image: url(care4dImg/New_backgroung_lg.jpg);
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: cover;

}

.center {
  display: flex;
  -webkit-justify-content: center;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
}

.topnav {
  position: relative;
  overflow: hidden;
  /* background-color:#2b3179; */
  color: inherit;
  padding: 1%;
}

.position-absolute {
  position: absolute !important;
}

.version-badge {
  /* color: #32324b; */
  color: #504fce;
}

.topnav-right {
  font-size: 18px;
  float: right;
}

Tabs {
  margin: 1%;
}
Tab {
  align-self: center;
  color: white;
  text-decoration: none;
  background-color: initial;
  display: initial;
  width: 15%;
  cursor: pointer;
  padding: 1%;
}

Tab:hover {
  color: white;
  display: initial;
  width: 15%;
  background-color: rgb(45, 56, 88);
}

Tab:active {
  align-self: center;
  color: white;
  text-decoration: none;
  background-color: rgb(45, 56, 88);
  display: initial;
  width: 15%;
}

.flex-center, .flex-center-wrap {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
  justify-content: center;
}

.sigin-text-field {
  width: 100%;
  max-width: 100%;
  font-size: 40px;
  /* padding: 6px 4vh 6px 14% !important; */
  margin-top: 40px;
}

.signin-input-field, .signin-select-field {
  width: 100%;
  max-width: 100%;
  font-size: 22px;
  background: transparent;
  border: 2px solid #fff;
  border-radius: 6px;
  color: white;
  padding: 6px 4vh 6px 14% !important;
  margin-top: 40px;
  outline: none;
}

.signin-select-field {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: url(care4dImg/icon-down.png);
  background-position: 3%;
  background-repeat: no-repeat;
  background-size: 10%;
}

.usernameIcon {
  background: url(care4dImg/username_icon.png) no-repeat scroll 5% 50%;
  background-size: 7%;
}

.password-icon {
  background: url(care4dImg/password_lock_icon.png) no-repeat scroll 5% 50%;
  background-size: 7%;
}

.signin-go-text {
  font-size: 36px;
  color: white;
  align-self: center;
}

.signin-check-icon[disabled] {
  filter: grayscale(1);
  opacity: 0.5;
}

.signin-check-icon:focus {
  outline: none !important;
}

.signin-check-icon:hover {
  opacity: 0.75;
}

.placeholder {
  color: inherit;
}

.signin-input-field::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  @extend .placeholder;
}

.signin-input-field:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  @extend .placeholder;
}

.signin-input-field::-ms-input-placeholder {
  /* Microsoft Edge */
  @extend .placeholder;
}

/* .signin-validation-msg {
  @include validation-message($right: 0, $font-size:1rem)
} */

#ShowError {
  color: #FF0000;
}

.tabcontent {
  color: inherit;
  width: 100%;
  display: none;
  overflow-y: auto;
  padding: 20px 20px 10px;
  height: 100%;
  background-color: #1c2238;
}

.dashboard {
  background: var(--main-bg-color);
  min-height: 100vh;
}
.dashboard-content{
  padding-top: 85px;
}
.zIndex3 {
  z-index: 3
}
/****************Navigation***************/
.navbar {
  padding: 0px;
  height: 88px;
}

.navbar-text {
  width: 87%;
}

.navbar-gradient {
  background-color: #7452cb;
  background-image: linear-gradient(270deg, #9052da 40%, #414fcb 60%);
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, .5);
}

.navbar-dark .navbar-brand{
  margin-left: 1%;
}
/*********************************/

.d-lg-block {
  display: block;
  margin-right: 0.5%;
}

.no-cursor-pointer {
  pointer-events: none;
}

.hr-separator {
  border-left: 1px solid #dbe2e6;
  height: 85px;
  margin: 0;
  padding-right: 15px;
}

.signout-no-decoration {
  font-size: 25px;
}
.navbar-text{
  color: white !important;
}

.table-responsive {
  display: block;
  width: 100%;
  min-height: 25%;
  overflow-x: auto;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar;
}

.panelDiv {
  border: 1px solid rgb(45, 56, 88);
  border-radius: 5px; /* TP-6476 */
  padding: 5px;  /* TP-6476 */
}

.new-edit-link {
  text-decoration: none !important;
  color: inherit !important;
  cursor: pointer;
}

.editLink {
  text-decoration: underline !important;
  color: inherit !important;
  cursor: pointer;
}

.non-editLink {
  text-decoration: none !important;
  color: inherit !important;
  cursor: none;
}

.hyperlink:hover {
  text-decoration: underline !important;
  color: lightcoral !important;
  cursor: pointer;
}

.hyperlink-active {
  text-decoration: underline !important;
  color: lightcoral !important;
  cursor: pointer;
  font-size: 20px;
}

.hyperlink-active-noUnderline {
  color: lightcoral !important;
  cursor: pointer;
}

.small-nav-link {
  font-size: 27px;
  padding-right: 10px;
  color: white;
  cursor: pointer;
}
.online-ring-container {
  position: relative;
  width: 15px;
  padding: 2px 19px 0px 10px;
}


.ring-container {
  position: relative;
  width: 15px;
  margin-left: 2px;  /* TP-6476 */
}

.ringring {
  border: 3px solid #62bd19;
  border-radius: 30px;
  -webkit-border-radius: 30px;
  height: 17px;
  width: 17px;
  position: absolute;
  left: -4px;  /* TP-6476 */
  top: -7px; /* TP-6476 */
  animation: pulsate 1s ease-out;
  -webkit-animation: pulsate 1s ease-out;
  animation-iteration-count: infinite;
  -webkit-animation-iteration-count: infinite;
  opacity: 0;
}

.circle {
  width: 9px;
  height: 9px;
  background-color: #62bd19;
  border-radius: 50%;
  position: absolute;
  top: -3px; /*TP-6476*/
  left: 0px; /*TP-6476*/
}

.Online-circle {
  width: 9px;
  height: 9px;
  background-color:  #62bd19; /* TP-6811 */
  border-radius: 50%;
  position: absolute;
  /* top: 10px; */
  left: 9px;  /* TP-6811 */
}

.close{
  cursor: pointer;
}
/*For blinker*/
@keyframes pulsate {
    0% {-webkit-transform: scale(0.1, 0.1); opacity: 0.0;}
    50% {opacity: 1.0;}
    100% {-webkit-transform: scale(1.2, 1.2); opacity: 0.0;}
}

.dropBox {
  border: 1px solid rgb(45, 56, 88);
  border-radius: 10px;
  margin: auto;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
	background: url(care4dImg/arrow_down.svg) no-repeat 95% TRANSPARENT;
	padding-right: 2%;
	color: white;
}

.fa-edit {
  color: #2BF289;
}

.modal-content {
  color: black;
}

.modal-backdrop.show {
  opacity: 0.8;
}

.__btn {
  display: block;
  height: 50px;
  width: auto;
  cursor: pointer;
  border: none;
  border-radius: 25px;
  background-color: #23c97d;
  color: #fff;
  padding: 0 25px;
  min-width: 110px;
}

.btn-plus {
  display: block;
  height: 35px;
  width: 38px;
  cursor: pointer;
  border: none;
  border-radius: 50%;
  background-color: #23c97d;
  color: #fff;
  padding: 0;
  min-width: 0;
}

.user-type-flag {
  padding: 0 8px;
  border-radius: 5px;
  font-size: 12px;
  background: #485890;
  height: auto;
}

.regTrailLabel {
  margin: auto;
  width: -moz-fit-content;
}

.form-control:focus,
.form-control.is-invalid:focus, .was-validated .form-control:invalid:focus,
.form-control.is-valid:focus, .was-validated .form-control:valid:focus {
  box-shadow: none;
}

.form-control.is-invalid, .was-validated .form-control:invalid,
.form-control.is-valid, .was-validated .form-control:valid {
  background-image: none;
  border-color:transparent;
  box-shadow: none;
}

.form-label {
  font-size: 20px;
  line-height: 24px
}

.cQqfUk {
  /* hidding table paggination */
  display: none;
}

.dot, .spinner-grow {
  height: 10px;
  width: 10px;
  border-radius: 50%;
  display: inline-block;
  vertical-align: initial;
}

.btn-green {
	display: block;
	height: 50px;
	width: auto;
	cursor: pointer;
	border: none;
	border-radius: 25px;
	background-color: #23c97d;
	color: white;
	padding: 0 25px 0 25px;
	min-width: 110px;
}

.btn-green:hover, .btn-green:focus, .btn-green:active{
  background-color: rgb(35, 201, 125);
}

.sendEmailDiv {
	margin: auto;
	width: 10px;
	font-size: 30px;
}

.not-active {
  pointer-events: none;
  cursor: default;
  text-decoration: none;
  display: block;
  height: 50px;
  width: auto;
  border: none;
  border-radius: 25px;
	color: #fff;
	background-color: #007bff;
	border-color: #007bff;
  padding: 0 25px;
  min-width: 110px;
}

.my-modal {
    width: 90vw;    /* Occupy the 90% of the screen width */
    max-width: 90vw;
}
.loader{
  position: absolute;
  z-index: 1;
  left: 50%;
}
/******* css for charts ************/
.chart-container {
  position: relative;
  height: 50vh;
  width: 80vw;
}
/**********************************/

/*********Breadcrumb Tail***********/
.breadcrumb-container {
	overflow: auto;
	width: 100%;
	display: flex;
	flex-wrap: nowrap;
  /* TP-6476 */
	/* position: absolute;
	top: 90px;
	z-index: 1000; */
	font-size: larger;
  padding: 10px 0px 5px;  /* TP-6476 */
}

.breadcrumb-item {
    cursor: pointer;
    text-overflow: ellipsis;
    white-space: nowrap;
    padding-left: 10px;
    padding-right: 10px;
    font-weight: bolder;
}

.breadcrumb-item:hover {
  opacity: 0.8;
}
.breadcrumb-item.active {
    color: #2bf288;
}

.breadcrumb-item-separator {
    margin-right: 5px;
    margin-left: 5px;
}

/******************/

.dashIconCls{
  position: absolute;
  right: 1%;
  top: 95px;
  color:#2bf288;
  cursor: pointer;
  font-size: xx-large;
  z-index: 1;
}

.dimScreen{
 width: 100%;
 height: 100%;
 background:rgba(255,255,255,0.5);
 position: fixed;
 top: 0;
 left: 0;
 z-index: 100; /* Just to keep it at the very top */
 text-align: center;
}

/**********Global Search - AutoCorrect*************/
.globalHuntCls{
  width: 40%;
  margin: auto;
  background-color: var(--main-text-color);
  border-radius: 20px;
}
.MuiInputBase-input{
  margin-left: 24px !important;
  color:var(--main-text-color);
}
.MuiInputBase-input::placeholder{
  text-align: center;
  opacity:1 !important;
  /* background: var(--main-text-color) !important; */
  color: var(--main-bg-color) !important;
}

.MuiFilledInput-underline, .MuiFilledInput-underline::after, .MuiFilledInput-underline::before, .MuiFilledInput-underline:hover::before{
  border-bottom-color: transparent !important;
}

.MuiInputBase-root, .MuiAutocomplete-paper{
  font-family: Titillium Web !important;
}
.MuiAutocomplete-inputRoot[class*="MuiFilledInput-root"]{
  padding-top: 0px !important;
}
.MuiAutocomplete-inputRoot[class*="MuiFilledInput-root"] .MuiAutocomplete-input{
  padding: 20px !important;
}
.MuiAutocomplete-option[data-focus="true"] {
  background-color: gainsboro !important;
}
.MuiAutocomplete-popupIndicator{
  color: var(--main-bg-color) !important;
}
/*************************************************/
/**********Graph CSS ***********************/
 .chartWrapper {
   width: 100%;
   overflow-x: auto;
   overflow-y:hidden;
 }

 .chartAreaWrapper {
   position: relative;
   overflow-x: scroll;
 }
/**************Hover Tooltip***************************/

#chartjs-tooltip {
  position: absolute;
  top:100px;
  left: 35vw;
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 10px;
  max-height: 85vh;
  background-color: rgb(255, 255, 255, 0.9);
  color: #1c2238;
  opacity: 0;
  overflow-y:scroll;
  pointer-events: none;
  z-index: 2;
 }

table.hoverTable{
 border-collapse: collapse;
}
table.hoverTable, table.hoverTable td, table.hoverTable th {
 border: 1px solid #1c2238;
}
/************************************************/

.page{
  /* position: static; */
  /* margin-top: 7vh; TP-6476 */
  margin-top: 5px; /* TP-6485 */
  /* position: absolute; */
}

/* .unknown-users-table .rdt_TableCol:nth-child(6),
.unknown-users-table .rdt_TableCell:nth-child(6){
  display: block;
 } */

 .hoverClipCopy {
  display: block;
}

/* Tooltip text */
.hoverClipCopy .tooltiptext {
  visibility: visible; /*TP-4084*/
  width: auto;
  height: auto;
  background-color: white;
  color: black;
  padding: 10px;
  border-radius: 6px;
  cursor: copy;

  /* Position the tooltip text */
  position: absolute;
  z-index: 5;
  bottom: 0;
  left: 50%;

  /* Fade in tooltip */
  opacity: 0;
  transition: opacity 0.3s;
  margin-left: 0%;
}

/* Show the tooltip text when you mouse over the tooltip container */
.hoverClipCopy:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
}

/* logs Tooltip text */
.hoverClipCopy-logs .tooltiptext {
  visibility: hidden;
  width: auto;
  height: auto;
  background-color: white;
  color: black;
  padding: 10px;
  border-radius: 6px;
  cursor: copy;

  /* Position the tooltip text */
  position: absolute;
  z-index: 5;
  bottom: 0;

  /* Fade in tooltip */
  opacity: 0;
  transition: opacity 0.3s;

}

/* Show the logs tooltip text when you mouse over the tooltip container */
.hoverClipCopy-logs:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
}

.hoverClipCopy-logs{
  width: 100%;
}

.copySelection {
  cursor: pointer !important;
}
.copySelection:active{
  color: #2bf288 !important;/*green color*/
}

.dropdown {
  cursor: pointer;
  display: inline-block;
  position: absolute;
  top:-10px;
}

.dropbtn {
  background: url("./care4dImg/addButton.png") no-repeat;
  /* TP-3068 -- commected out for this ticket	
  scale:0.7; */
  transform: scale(0.7);
  width: 80px;
  height: 80px;
}

.dropdown-content {
  /* display: none; */
  position: absolute;
  min-width: 50px;
  color:black;
  background-color: aliceblue;
  z-index: 10;
}

.dropdown-content a {
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

.dropdown-content a:hover {
  color:black !important;
  scale:0.9;
  transform: scale(0.9);
}

.centerByPosition{
  position: absolute;
  left: 50%;
}

.serverErrorCss{
  position: absolute;
  right: 0;
  bottom: 0;
  margin-right: 10px;
  z-index: 1001;
}

.word-break-all{
  word-break: break-all;
}

.text-ellipses{
    display: block;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}
/* TP-3068-- .dropbtn {
  scale:0.6;
  transform: scale(0.6);
} */

.media{
  display: none !important;
}

.text-hover-cursor{
  cursor: pointer;
  border-bottom-style: dotted;
  border-width: thin;
}
/* .div-text-area{
  max-height: 1.5rem;
  color: white;
  background-color: inherit;
  resize: none;
  border: 0px;
} */
.arrow {
  border: solid white;
  border-width: 0 5px 5px 0;
  display: inline-block;
  padding: 5px;
  cursor: pointer;
}
.left {
  transform: rotate(135deg);
  -webkit-transform: rotate(135deg);
}
.chart-title{
  font-size : 20px;
}
.plusminus{
  font-size:30px;
  cursor: pointer;
  padding: 0 10px 0 0;
  font-weight: bold;
}
.homepage-pie-labels{
  padding-left: 30%;
}
/**********Responsive*************/
/*Medium devices (on potrait mpde, 425px and less)*/
@media (max-width: 425px) {
  .media{
    display: block !important;
    padding-top: 25vh;
  }
  .page .media-show{
    display: none;
  }
}
/*Small devices (landscape phones, 576px and up)*/
@media (max-width: 576px) {

}
/*Medium devices (tablets, 768px and up)*/
@media (min-width: 576px ) {
  #byUsers{
    margin-top: -25%;
  }
  #byARMC, #byTLC, #byTGC{
    margin-top: -15%;
  }
}
/*Medium devices (smartphone, 768px and up)*/
@media (max-width: 992px) {
  /* For mobile phones: */
  .home, .dashboard {
    width:100%;
  }
  .navbar{
    height: auto !important;
  }
  .navbar-brand{
    display: none;
  }
  .hr-separator{
    display: none;
  }
  .navbar-toggler{
    height: 100%;
    padding: none;
    font-size: none;
  }
  .navbar-text {
    display: none;
    font-size: 16px;
    flex-basis: 50%;
  }
  .dashboard-content{
    padding-top: 55px;
  }
  .dashIconCls{
    top: 55px;
  }
    .breadcrumb-container {
    /* top: 43px; */
    padding: 5px 0px;    /* TP-6476 */
  } 
  #chartjs-tooltip, #lapy{
    display: none;
  }
  .chart-title {
    font-size: 14px;
  }
  .alert {
    padding: 0.25rem 0.25rem;
    font-size: 16px;
  }
  select#onlyFilteredUsers, div .react-dropdown-tree-select{
    /* font-size: x-small; */
  }
  .cFiWpo, .fJRgfo, .hNRBLd, .hElmdu, .sc-jTzLTM {
    font-size: 12px !important;
    min-width: 8vw !important;
    max-width: 15vw !important;
  }
  .cBYLcD{
    font-size: 10px !important;/*each cell in table*/
    min-width: 8vw !important;
    max-width: 15vw !important;
  }

  .cBYLcD{
    font-size: 10px !important;/*each cell in table*/
  }
  .panelDiv {
    font-size: small;
  }
  .media-show-sm{
    display: none !important;
  }
  .sc-cSHVUG.jJknLL{
    display: none;/*removed header for table title*/
  }
  .sc-gqjmRU{
    font-size: 12px !important;
  }
}

.yellow{
  color: #ffff00;
}

.socket-info{
  padding: 0px 238px 0px 281px;
}

.register-device-info{
  padding: 0px 238px 0px 190px;
}

.red {
  color: #FF0000;
}

.orange {
  color: #FD7E14;
}
.socketIconCls{
  color:#2bf288;
  cursor: pointer;
  font-size: xx-large;
  float: right;
}

.dropdownlist {
  display: flex;
  flex-direction: row;
}
.Socket-connection{
  padding: 24px 0px 0px 0px;
  cursor: pointer;
}
.device-registered {
  padding: 24px 0px 0px 20px;
}

.de-registration-icon{
  color:#fd0000;
  cursor: pointer;
  font-size: 20px !important;
  z-index: 1;
  margin-left: 40px;
}
/*Large devices (desktops, 992px and up)*/
@media (min-width: 992px) {
  #byUsers{
    margin-top: -35%;
  }
  #byARMC{
    margin-top: -48%;
  }
  #byTLC{
    margin-top: -47%;
    margin-bottom: 14%;
  }
  #byTGC{
    margin-top: -47%;
    margin-bottom: 14%;
  }
}
/*Extra large devices (large desktops, 1200px and up)*/
@media (min-width: 1920px) {

}
/*Only for Mozilla Firefox Browser*/
@-moz-document url-prefix() {
  .dropbtn {
    scale:0.8;
    transform: scale(0.8);
  }
}
/**********************************/

.logmeIn-text-div{
  margin-top: 30%;
  font-size: 20px;
}

.btn-logmeIn-green{
  background-color: #23c97d;
  display: block;
  height: 50px;
  width: auto;
  cursor: pointer;
  border: none;
  border-radius: 25px;
  color: #fff;
  padding: 0 25px;
  min-width: 110px;
}

.btn-logmeIn-red{
  background-color: red;
  display: block;
  height: 50px;
  width: auto;
  cursor: pointer;
  border: none;
  border-radius: 25px;
  color: #fff;
  padding: 0 25px;
  min-width: 110px;
  margin-left: 50px;
}

.logmeIn-footer{
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin: 10%;
}

.cSwvfT{
  font-size: 16px !important;
  min-width: 10vw !important;
  max-width: 16vw !important;
}

/* TP-6387 */
#input-with-icon-outlined-input{
  margin-left: 0px !important;  
}


/* TP-6390 */
.legend-cls{
  padding-bottom: 15px; 
}

/* TP-6475 */
#notifyIcon{
  position: relative;
  display: flex;
  flex-direction: row;
  margin: 0px 14px;
  background-color: transparent;
  border: none;
}

/* TP-6475 */
#notifyIcon:hover{
  cursor: pointer;
  
}

/* TP-6475 */
.bell-icon{
  color: #e6e9ef;
}

/* TP-6475 */
#superscriptValue{
  position: absolute;
  top: -9px;
  left: 0px;
  font-size: 16px;
  color: #ffffff;
  background: #bbb506;
  border-radius: 50%;
  width: 22px;
  height: 22px;
  padding: 0px;
  margin-top: 0px;
 font-weight: bolder;
 line-height: 20px;
 z-index: 4;
}


/* TP-6476 */
@media (max-width: 768px) {
  .modal-content{
    display: flex;
    justify-content: space-around;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
  }
}
