/* Written by Prerana Ray
    dated: 28022023 -- TP-4921
*/

#approval_modal > .modal-content {
    width: fit-content !important;    
}

.btn-green-new {
    display: inline-block;
    padding: 5px 25px;
    background: rgb(35, 201, 125);
    margin-left: 5vw;
    margin-top: 0;
    margin-bottom: 1%;
    vertical-align: middle;
    width: auto;
    height: 40px;
    border: none;
    border-radius: 25px;
}

.btn-green-new:hover, .btn-green-new:focus, .btn-green-new:active{
    background-color: rgb(35, 201, 125);
}

.btn-red-new {
    display: inline-block;
    padding: 5px 25px;
    background: rgb(201, 35, 49);
    margin-left: 5vw;
    margin-top: 0;
    margin-bottom: 1%;
    vertical-align: middle;
    width: auto;
    height: 40px;
    border: none;
    border-radius: 25px;
}