
 #donut-chart-box.donut-container-box-div {
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 100%;
  }
  
  #donut-chart-box .graphbox {
    width: 450px;
    /* margin: 0 10% auto; */
    margin: -20px 10px 20px 10px;
    position: relative;
  }
  
  #donut-chart-box.legends {
    width: 300px;
  }
  
  #donut-chart-box.legends li.legend {
    list-style: none;
    display: inline-flex;
    width: 50%;
  }
  
  #donut-chart-box .legends li.legend .icon {
    width: 20px;
    height: 20px;
    margin-right: 4px;
    margin-bottom: 5px;
    border-radius: 50%;
  }
/* 
  #donut-chart-box .chart__legend_box{
  display: inline-block;
  position: relative;
  top: 50%;
  transform: translateY(-50%);  
} */


#donut-chart-box .legend__item--inline{
  display: flex;
  margin:5px;
  width:200px 
}
#donut-chart-box .legend__item-badge, .legend__item-text, .legend__item-value{
  margin: 2px;
}

#donut-chart-box .legend__item-badge{
  width: 10px;
  height: 10px;
  margin-top: 10px;
}

#donutChartbox path{
  cursor: pointer;
}

#donutLegendbox {
  display: flex;
  flex-wrap: wrap;
  margin-top: -35px;
}