
  .download-reports-loader {
    bottom: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: auto;
    pointer-events: all;
    z-index: 99996;
    border: none;
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    cursor: wait;
    position: fixed;
    background-color: rgba(108,98,98,.33)!important;
    
}