
#d3ChartField{
    width: 100%;
    height: 60vh;
    /* margin-left: 10px; */
    position: relative;
}

path.domain  {
    stroke: rgb(44, 80, 156);
}
/* .tick text {
    fill:rgb(239, 239, 248)
} */
 
.wrapper {
    font-size: 16px;
    color: #666;
    padding: 2px;
}
  
svg {
   overflow: visible;
}
  
text {
  fill: gainsboro;
  color: gainsboro;
  font-size: 16px;
}
  
.subtitle text {
   text-decoration: underline;
  font-size: 12px;
}
  
.axis .domain,
.axis.xAxis line {
   display: none;
}

.axis {
	font: 10px sans-serif;
}

.axis path,
.axis line {
  fill: none;
  stroke: #D4D8DA;
  stroke-width: 2px;
  shape-rendering: crispEdges;
}

.line {
  fill: none;
  stroke: #6F257F;
  stroke-width: 5px;
}

.overlay {
  fill: none;
  pointer-events: all;
}

.focus circle {
  fill: #F1F3F3;
  stroke: #6F257F;
  stroke-width: 5px;
}

div.tooltip {	
  position: absolute;			
  text-align: center;			
  width: 100px;					
  height: 50px;								
  background: #59616a;	
  border: 0px;		
  border-radius: 8px;			
  pointer-events: none;			
}
  
/* .hover-line {
  stroke: #6F257F;
  stroke-width: 2px;
  stroke-dasharray: 3,3;
} */
  
