.simple-select-standard /* > .MuiInputBase-input */{
    padding-bottom: 25px !important;    
}

/* TP-6478 */
.disable-pointer-event{
    margin-top: auto;
    margin-bottom: auto;
    padding: 0px !important;
}

#simple-select-standard{
    height: 40PX;
    margin-top: auto;
    margin-bottom: auto;
    background-color: transparent;
    padding: 5PX 0px;
    margin-left: 10px !important;
}