.alert-msg {
    color: white;
    border-radius: 10px;
    position: absolute;
    top: 16%;
    padding: 20px;
    display: flex;
    align-items: center;
    z-index: 1111;
    left:50%;
    transform:translate(-50%,-50%)
}
.alert-msg p {
    margin: 0;
}
.alert-error {
    background: lightcoral;
}
.alert-success {
    background: lightgreen;
}
/* .close {
    color: white;
    cursor: pointer;
    margin-right: 10px;
} */
