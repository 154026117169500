/* style={{marginLeft:'10px',marginRight:'50px',cursor:'pointer'}} */

.user-usage-icon{
    margin-left: '10px';
    margin-right: '50px';
    cursor: pointer;
    margin-right: 35px;
}

.svg-container svg{
    height: 25px;
    width: 25px;
}

.svg-container svg path {
    stroke: white;
}

.download-csv-container svg{
    height: 25px;
    width: 25px;
    margin-right: 50px;
    cursor: pointer;
}

.align-cn{
    text-align: center;
}

.strike-red{
    text-decoration:line-through;
    color:red;
}

.chart-control-container{
    display: flex;
    flex-direction: inherit;
    justify-content: end;
    margin-bottom: 10px;
    margin-top: 10px;
}

.chart-type{
    width: 100px;
    height: auto;
    direction: ltr;
    background: content-box;
    color: #fff;
    border-color: #36426b;
    margin: 0 20px;
}

#chart_end_date, #chart_start_date {
    color: #fff;
    background: content-box;
    border-color: #36426b;
    width: 80%;
}

#d3ChartField svg{
    background-color: white;
}