
#chart-analytics-conatiner {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 0;
    margin-top: 50px;
}

.chart-handler-div{
    display: flex;
    gap:10px;
}

.analytics-chart-container-divided {
    display: inline-flex;

}

.analytics-chart-container-divided > div {
    padding: 1em;
    margin-top: 1em;
}

.analytics-chart-container-center {
    display: inline-flex;
    gap: 100px;
    margin-top: 50px;
    justify-content: center;
    align-items: center;
} 

.stack-bar-chart-container{
    margin-right: auto;
}