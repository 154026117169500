:root{
    --main-text-color:white;
    --red: #E02127;
    --green:#28a745;
    --yellow:#ffff00;
    --main-bg-color:#1c2238;
  }



#Jobs-details-form .form-group{
    padding: 0px 1px 1px 17px;
}

#procedure-panel .form-group{
    padding: 0px 1px 1px 0px;
}

/* #Jobs-details-form .form-group-col {
    padding: 0px 1px 1px 17px;
} */



/* #Jobs-details-form .form-group > input {
    clear: both; 
    margin-right: 15px;
    width: 69%;
    padding: 0px 0px 0px 0px;
    margin: 10px 10px 3px 10px;
    margin-top: 10px;
    text-align: inherit;
} */

#Jobs-details-form {
    margin-top: 40px;
}

#Jobs-details-form .coll-panel .btn-block{
display: block;
width: 85%;
margin: 10px 10px 10px 16px;
}

#Jobs-details-form .icon-text-align {
display: initial;
white-space: nowrap;
text-overflow: ellipsis;
overflow: hidden;
}

#desc{
    border-radius: 8px;
    width: 70%;
    margin-left: 5px;
    background-color: #6c757d;
    color: #C5CCCC;
    margin-left: 17%;
}


.col-space-8 {
    margin-left: 8% !important;
}

.col-space-3 {
    margin-left: 3% !important;
}

.task-ref-result {
    display: grid;
    margin-left: 25%;
    margin-top: -25px;
} 

#container {
    display: flex;
    flex-wrap: wrap;
}

#container {
    display: flex;
    flex-wrap: wrap;
}

#right-job{
    display: inline-block;
    width: 50%;
    /* margin-top: 15px; */
}
/* #right-job .form-group{
    padding: 0px 1px 1px 0px; 
} */

#left-job{
    display: inline-block;
    width: 50%;
    margin-top: 56px;
}

#left-job .form-group-col{
    padding: 0px 1px 1px 17px;
}

#procedure-panel {
    margin-top: 15px;
}

/* #task-url{
    border-radius: 8px;
    margin-left: 5px;
    background-color: #6c757d;
    color: #C5CCCC;
    margin-top: 12px;
} */



#Jobs-details-form > th {
    background-color: #7452cb;
    font-size: 21px;
    color: white;
    border-radius: 3px;
    cursor: pointer;
    position: relative;
  }

/* #Jobs-details-form input[disabled] {
    color: #303030;
    background-color: #FFFFFF;
    padding: 0px 5px;
    font-family: Titillium Web;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
}


#Jobs-details-form .form-group-col > input {
    clear: both; 
    margin-right: 15px;
    width: 25%;
    padding: 0px 0px 0px 5px;
    margin: 10px 10px 10px 10px;
    margin-top: 10px;
    text-align: inherit;
} */


#left-job input[disabled] {
    color: #303030;
    background-color: #FFFFFF;
    padding: 0px 5px;
}

#left-job .form-group-col>input {
    clear: both;
    margin-right: 15px;
    width: 25%;
    padding: 0px 0px 0px 5px;
    margin: 10px 10px 10px 10px;
    margin-top: 10px;
    text-align: inherit;
}

#right-job input[disabled] {
    color: #303030;
    background-color: #FFFFFF;
    padding: 0px 5px;
    font-family: Titillium Web;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    width: 63%;
}

#right-job .form-group-col>input {
    clear: both;
    margin-right: 15px;
    width: 17%;
    padding: 0px 0px 0px 5px;
    margin: 10px 10px 10px 10px;
    margin-top: 10px;
    text-align: inherit;
    margin-left: 8%;
}


#right-job > label {
    width: 10%;
}
.jobs-exp-text-area {
    color: #303030;
    background-color: #FFFFFF;
    font-family: Titillium Web;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    width: 63%;
    padding: 0px 5px;
    box-sizing: border-box;
    border-radius: 4px;
    resize: none;
}

.jobs-text-desc{
    color: #303030;
    background-color: #FFFFFF;
    width: 69%;
    padding: 0px 5px;
    box-sizing: border-box;
    margin-left: 10px;
    border-radius: 4px;
    resize: none;
}

 #Jobs-details-form .inline-flex-result {
    display: flex;
}


.display-grid-jobs {
    display: grid;
} 

.shrink { 
transition: all .2s ease-in-out; 
}
    
.shrink:hover { 
transform: scale(0.9);
cursor:pointer; 
}

.ui-form {
    display: block;
    width: 85%;
    margin: 10px 10px 10px 16px
}


.absolute {
    position: absolute;
    right: 0;
}

.job-button {
	box-shadow: 0 6px #1C2238;
    border-radius: 8px;
    width: 63%;
    background-color: #6c757d;
    color: #C5CCCC;
    margin-bottom: 5px;
    padding: 0px 0px 0px 5px;
}
.task-ref-flex {
    display: grid;
    margin-left: 25%;
    margin-top: -10px;
}

.external-ref-flex {
    display: grid;
    margin-left: 25%;
    margin-top: -24px;
    margin-bottom: 12px;
}

.job-button:hover {
	box-shadow: 0 8px #1C2238;
	transform: translateY(-3px);
}
.job-button:active {
	background-color: #1C2238;
	box-shadow: 0 5px #1C2238;
	transform: translateY(4px);
}



.inner-addon > input {
    background-color: #384571;
    width: 100%;
    font-size: 18px;
    margin-bottom: 2px;
    color: white;
    border-radius: 3px;
    border: none;
  }

/* style icon */
.inner-addon .fa {
  position: absolute;
  padding: 5px;
  pointer-events: none;
  color: var(--main-text-color);
}

/* align icon */
.left-addon .fa  { left:  0px;}
.right-addon .fa { right: 0px;}

/* add padding  */
.left-addon input  { padding-left:  30px; }
.right-addon input { padding-right: 30px; }


.download-report-space {
    padding: 5px;
    cursor: pointer;
 }

 .download-zip-icon{
    padding: 10px;
    cursor: pointer;
    color: #007bff;
 }

.report-green {
    color: var(--green) !important;
}
.report-yellow {
    color: #ffff00 !important;
}

.report-red {
    color: var(--red) !important;
}


#left-job .green  {
    background-color: var(--green) !important;
    color: var(--main-text-color) !important;
}

#left-job  .yellow {
    background-color: #ffff00 !important;
} 

#left-job  .red {
    background-color: var(--red) !important;
    color: var(--main-text-color) !important;
}

#left-job .custom-width {
    margin-left: 10px;
    width: 470px !important;
}
#left-job .input-icon-container {
    display: inline-flex;
    flex-direction: inherit;
    width: 72%;
}


#right-job .select-dropdown {
    border-radius: 10px;
    box-shadow: 0 10px 25px rgb(0 0 0 / 10%);
    background-color: white;
    width: 48%;
    margin-left: 32%;
}

#right-job .dropdown-header {
    padding: 10px;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;

}

#right-job .dropdown-body {
    padding: 5px;
    border-top: 1px solid #E5E8EC;
    display: none;
}

#right-job .dropdown-body.open {
    display: block;
    position: absolute;
    background-color: #f9f9f9;
    box-shadow: 0px 8px 16px 0px rgb(0 0 0 / 20%);
    border-radius: 10px;
    min-width: 23%;
    margin-left: 10px;
}

#right-job .dropdown-item {
    padding: 10px;
    background-color: transparent!important;
}

#right-job .dropdown-item:hover {
    cursor: pointer;
}

#right-job .dropdown-item-dot {
    opacity: 0;
    color: #91A5BE;
    transition: all .2s ease-in-out;
}

#right-job .dropdown-item-dot.selected {
    opacity: 1;
}

#right-job .icon {
    font-size: 13px;
    color: #91A5BE;
    transform: rotate(-90deg);
    transition: all .2s ease-in-out;
}

#right-job .icon.open {
    transform: rotate(90deg);
}

.display-flx {
    display: flex !important;
    flex-direction: row;
  }

.pdf-icon-fa {
    margin: 5px;
    color: #C0FD05;
    cursor: pointer;
    margin-top: 8px;
}


.bNEAHq, .uDGoO, .eDCnJd, .iyghrf, .ckipKW {
    font-size: 16px !important;
    font-weight: 400 !important;
    line-height: normal !important;
}
.ckipKW:first-of-type {
    padding-left: 8px;
}

.jobs-list-class .iyghrf {
  font-size:13px !important;  
}

.jobs-list-class .jDHQPG{
    position: relative;
    display: block;
    width:100%;
    height:100%
}

