

#form-wrapper
{
    top: 6%!important;
    height: 630px;
    border-radius: 25px;
    margin-top: 1%;
    margin-left: 20%;
    margin-right: 20%;
    padding: 1% 2% 4% 1%;
}

.formheader{
    text-align: center;
}

.form-container {
    margin-top: 6%!important;
}

.globalCustomerHuntCls {
    height: 37px;
    color: black;
}

.globalGroupsHuntCls {
    height: 37px;
    background-color: var(--main-text-color);
    display: inline-block;
    min-height: 37px;
    width: 100%;
    max-width: 600px;
}

.flex-center {
    display: flex;
    justify-content: center;
}

.btn-close-red{
    display: block;
    height: 50px;
    width: auto;
    cursor: pointer;
    border: none;
    border-radius: 25px;
    color: #fff;
    padding: 0 25px;
    min-width: 110px;
    background-color:red;
}

.btn-save-green{
    display: block;
    height: 50px;
    width: auto;
    cursor: pointer;
    border: none;
    border-radius: 25px;
    color: #fff;
    padding: 0 25px;
    min-width: 110px;
    background-color:#23c97d;
}

.flex-center > button {
    margin: 30px;
  }

/* .label {
    display: block;
    margin-bottom: 6px;
    font-weight: 600;
  }

  .selection {
    position: relative;
    padding: 5px;
    border: 1px solid gray;
    background: #fff;
  }  

  .select {
    position: relative;
    display: inline-block;
    width: 320px;
  }

  .select:focus {
    outline: 0;     
  }

  .select .selection {
    box-shadow: 0 0 1px 1px white;
  }

  .value {
    position: relative;
    display: inline-block;
    padding: 5px 10px;
  }
  
  .multiple {
    padding-right: 30px;
    margin-right: 5px;
    background: rgb(26, 4, 4);
    color: white;
  }

  .delete {
    position: absolute;
    top: 0;
    right: 0;
    display: block;
    padding: 10px;
    font-size: 10px;
    cursor: pointer;
  }

  .placeholder {
    padding: 5px 10px;
    color: gray;
  }


  .arrow {
    position: absolute;
    top: 5px;
    right: 5px;
    display: block;
    padding: 10px;
    font-size: 10px;
    color: gray;
  }

  .options {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    border: solid gray;
    border-width: 0 1px;
    background: #fff;
  }

  .option {
    padding: 10px 15px;
    border-bottom: 1px solid gray;
    cursor: pointer;
  }

  .option .selected {
    border: 1px solid white;
    margin: -1px -1px 0;
    background: black;
  }

  .option .focused {
    background: gray;
  }

  .checkbox {
    content: '';
    vertical-align: top;
    display: inline-block;
    width: 16px;
    height: 16px;
    padding: 2px;
    border: 1px solid gray;
    border-radius: 2px;
    margin: 2px 12px 0 0;
    color: #fff;
    font-size: 10px;
  }

  .checkbox .selected  {
    border-color: black;
    background: white;
  } */

/* .form-group label{
    float: left;
}

.form-group-header{
    margin-top: 6%!important;
}
.form-group-header label {
    float: left;
}

form-container label{
    float: left; 
    margin-top: 6%!important;
} */

/* #form-wrapper input{
    display:block;
} */