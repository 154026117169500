#connection-row{
    display: flex;
    margin-top: 50px;
    justify-content: center;
    align-items: center;
}

#connection-row > ul.inline{
    list-style: none;
    padding: 0;
    margin-bottom: 0;
    -webkit-margin-before: 0;
    -webkit-margin-after: 0;
    -webkit-margin-start: 0px;
    -webkit-margin-end: 0px;
    -webkit-padding-start: 0px;
}

#connection-row > ul.inline,
li {
  margin-left: 0;
  transition: all 0.5s;
  
}

#connection-row > ul.inline,
li.selected {
  border-bottom: 2px solid #337ab7;
}

.dbxVHa, .jkCGiJ, .crpWmy{
  line-height: normal !important;
  font-size: 13px !important;
  font-weight: 400 !important;
}
