h1 { 
    text-align:center; 
    margin-top: 80px;
  }
  
 #stack-y-axis{
  display: none;
 } 

 .domain { 
    fill: none; 
    stroke: #000; 
    stroke-width: 1px; 
} 

#stockChart{
  height: 420px !important;
  width: 660px !important;
}

#stackBarChart {
  width:100%;
}

#stackBarChart svg{
  overflow: hidden !important;
}

.stack-title-txt{
  text-align: center;
  margin-bottom: 20px;
}