/* CustomerForm.css */

.one {
  padding: 1% 3% 0% 1%;
  margin-bottom: 5px;
}

.one :after {
  display: table;
  clear: both;
}

.two {
  float: left;
  width: 50%;
  overflow: hidden;
  margin-bottom: 10px;
  padding: 1% 3% 0% 1%;
}

.three {
  float: left;
  width: 100%;
  overflow: hidden;
  /* margin-bottom: 5px; */
  padding: 1% 3% 0% 4%;
}

.inptError {
  color: #dc3545;
  text-decoration-style: initial;
  font-size: small;
}

.four {
  margin-bottom: 5px;
  width: 80%;
  padding: 1% 3% 0% 4%;   /* TP-6825 */
  /* padding: 5px 2px 5px;   */
}

#genericNameAdmindiv,
#genericNameExpertdiv,
#genericNameTechniciandiv {
  display: flex;
}

ul {
  list-style-type: none;
}

div .pricefld {
  margin-left: 10%;
  margin-top: 3%;
  margin-right: 10%;
  margin-bottom: 4px;
}

div .pricefld-date {
  margin-left: 10%;
  margin-right: 10%;
  margin-bottom: 15px;
}

.pricefld :after {
  display: table;
  clear: both;
}

.custfld {
  margin-left: 10%;
  margin-right: 5%;
  max-height: 65vh;
  overflow-y: auto;
}

.two-custfld {
  margin-left: 11%;
  margin-right: 9%;
  max-height: 65vh;
  overflow-y: auto;
}


.detfld {
  margin-left: 10%;
  margin-top: 30px;
  margin-right: 10%;
}

input[type=text],
input[type=email],
input[type=number],
input[type=date] {
  width: 100%;
  padding-left: 10px;
  box-sizing: border-box;
  border-radius: 5px;
}

input[type=checkbox] {
  margin-left: 7px;
  margin-right: 7px;
}

h3 {
  text-align: center;
  padding-top: 5%;
}

h4 {
  text-align: left;
  padding-left: 20%;
}

div .btn-green {
  display: inline-block;
  padding: 0 25px;
  background: rgb(35, 201, 125);
  margin-left: 45vw;
  margin-top: 0;
  margin-bottom: 1%;
  vertical-align: middle;
  width: auto;
  height: 40px;
  border: none;
  border-radius: 25px;
}

div .btn-gray {
  display: inline-block;
  padding: 0 25px;
  background: rgba(107, 117, 113, 0.664);
  margin-left: 45vw;
  margin-top: 0;
  margin-bottom: 1%;
  vertical-align: middle;
  width: auto;
  height: 40px;
  border: none;
  border-radius: 25px;
}

.btn-hover-green {
  background: #3EB493
}

div .btn-red {
  display: inline-block;
  padding: 0 25px;
  background: rgb(201, 35, 49);
  margin-left: 44vw;
  margin-top: 0;
  margin-bottom: 1%;
  vertical-align: middle;
  width: auto;
  height: 40px;
  border: none;
  border-radius: 25px;
}

/*TP-2659*/
div .button-btn {
  display: block;
	height: 50px;
	width: auto;
	cursor: pointer;
	border: none;
	border-radius: 25px;
  color: white;
	padding: 0 25px 0 25px;
	min-width: 110px;
  margin-left: 25%;
}

.button-btn-red {
  background-color: #D8000C !important;
}

.button-btn-green {
  background-color: #23c97d !important;
}

.button-btn-green:hover, .button-btn-green:focus, .button-btn-green:active{
  background-color: rgb(35, 201, 125) !important;
}

label {
  height: 20px;
  display: inline-block;
}

.customDrop {
  position: relative;
  box-sizing: border-box;
  border-radius: 5px;
  width: auto;
  margin-left: 2%;
}

.react-datepicker-wrapper{
  margin-left: 2%;
}

.switch {
  position: relative;
  display: inline-block;
  width: 50px;
  height: 25px;
  margin-left: 2px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 16px;
  width: 15px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked+.slider {
  background-color: #2196F3;
}

input:focus+.slider {
  box-shadow: 0 0 1px #2196F3;
}

input:checked+.slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

textarea {
  width: 100%;
  padding-left: 10px;
  box-sizing: border-box;
  border-radius: 5px;
  margin: 8px 0;
}

.fa-copy {
  float: right;
}

.license-text {
  padding: 2px 5px;
  font-size: 15pt;
  height: 97px; /*TP-4283*/
  width: 360px;
  position: absolute;
  overflow: hidden;
}

.license-div {
  left: 28%;
}

.button-margin {
  left: -6%;
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

/*Rounder slider end*/

.mandatory-note {
  position: absolute;
  top: 60vh;
}

.cust-number {
  width: auto !important;
  margin-left: 2%;
  margin-bottom:3%;
}

.tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: white;
  color: black;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;

  /* Position the tooltip */
  position: relative;
  z-index: 1;
  margin-left: 300px;
}

.position-relative {
  position: relative;
}

.d-blk {
  display: 'block';
}

.d-none {
  display: 'none';
}

/**TP-6260*/
#safetyScanFrequencydiv{
  display: none;
}

.partitioned {
  padding-left: 15px !important;
  color: rgb(201, 35, 49);
  background-color: #1c2238;
  letter-spacing: 35px;
  outline: none;
  border: 0;
  background-image: linear-gradient(to left, rgb(0, 0, 0) 70%, rgba(255, 255, 255, 0) 0%);
  background-position: bottom;
  background-size: 50px 1px;
  background-repeat: repeat-x;
  background-position-x: 35px;
  height: 35px;
  font-size: 20pt;
  width: 300px;
  min-width: 290px;
}

.divInner {
  left: 0;
  position: sticky;
}

.divOuter {
  width: 400px;
  overflow-x: hidden;
}

.bevfld {
  margin-left: 9%;
  margin-top: 12px;
  margin-right: 10%;
}

input[disabled] {
  color: #ccc;
}

.pending-app-class {
  margin-left: 15%;
  padding: 10px 10px 20px 47px;
}

.fa-icon-innter-text {
  font-family: Titillium Web !important;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
}

.file-form-control {
  display: block;
  width: 50%;
  height: calc(1.5em + .75rem + 2px);
  padding: .375rem .75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #eff7ff;
  margin-left: 10%;
  background-color: #1C2238;
  background-clip: padding-box;
  border: 1px solid #1C2238;
  border-radius: .25rem;
  transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out
}

/* .btn-blue-primary {
  display: block;
  height: 30px;
  width: auto;
  cursor: pointer;
  border: none;
  border-radius: 25px;
  background-color: #2196F3;
  color: white;
  padding: 0 20px 0 20px;
  min-width: 110px;
  margin: 2%;
} */

#upload-image-div .btn-blue-primary {
  /* display: inline-block;
  background-color: indigo;
  color: white;
  padding: 0.5rem;
  font-family: sans-serif;
  border-radius: 0.3rem;
  cursor: pointer;
  margin-top: 1rem; */
  display: inline-block;
  height: 30px;
  width: auto;
  cursor: pointer;
  border: none;
  border-radius: 25px;
  background-color: #2196F3;
  color: white;
  padding: 3px 115px 0px 90px;
  min-width: 110px;
  margin: 1% 10% 2% 18% 

}

.btn-gray-primary {
  display: block;
  height: 30px;
  width: auto;
  cursor: pointer;
  border: none;
  border-radius: 25px;
  background-color: #BABCC3;
  color: black;
  padding: 0 20px 0 20px;
  min-width: 110px;
  margin: 2%;
}

#image-preview {
  margin: 2%;
  margin-left: 30%;
  width: 250px;
  max-width: 250px;
}

#image-preview img{
  display: block;
    max-width:180px;
    max-height:180px;
    width: auto;
    height: auto;
}

.text-danger {
  /* position: fixed; */
  left: 100px;
  margin-top: 16%;
  font-size: large;
}

#modify-invitee-email .text-email-danger {
  /* position: fixed; */
  left: 100px;
  margin-top: 16%;
  color: #dc3545!important;
  font-size: large;
}

#enterpriseForm .text-danger {
  position: fixed;
  left: 100px;
  margin-top: 16%;
}
.customer-checkbox{
  margin: 5px;
  margin-left: 10%;
  width: 80%;
  padding: 1% 3% 0% 1%;
}

#file-upld-valiation  li {
  color:#fff;
  list-style:none;
  position: relative;
  padding-left:25px;
  line-height: 2;
  font-size:15px;
  
}

#file-upld-valiation ul{
  margin-bottom:1%
}

li:before {
  font-family:Titillium Web !important;
  position: absolute;
  left: 0;
  color:#2196F3;
  font-size:16px;
}

file-upload-div {
  color:#fff;
  text-align: center;
  font-size: 60px;
  margin-left: 5%;
}

li.ddtwo:before {
  content:"\f00c";
  margin-bottom: 0.5rem;
}

.delete-upload-icon{
  color:#fd0000;
  cursor: pointer;
  font-size: 35px !important;
  z-index: 1;
  margin: 5px 10px 10px 114px;
  transition-delay: 5s;
}

div .btn-red {
  color: #fff !important;
  text-decoration: none;
  background-color: #fd0000 !important;
}

#error {
  color: #D8000C;
  margin-left: 12%;
  margin-bottom:12%;
}

a.thumb1 {
  position: relative;
  float: none;
  margin: 20px 10px 10px 44px;
  display: block;
  width: 75px;
}

#thumb img{
  display: block;
  max-width:210px;
  max-height:210px;
  width: auto;
  height: auto;
}

.loading-overlay {
  display: none;
  background: rgba(255, 255, 255, 0.7);
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  z-index: 9998;
  align-items: center;
  justify-content: center;
}

#pwdexpirydurationdiv {
  margin-top: -6px;
}


.coll-panel-btn:focus {
  outline: 0;
  box-shadow: none;
}

#customfld > .border {
  border: 0px solid #dee2e6!important;
}

#installbydatediv > label{
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
} 

/*Medium devices (smartphone, 768px and up)*/
@media (max-width: 992px) {
  .mandatory-note {
    position: static;
  }
}

.upload-model{
  display: block;
  width: 150px;
  height: 40px
}