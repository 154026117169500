/*  newUserDetails.css  */

/* TP-6476 */

.userDetailsselectBar {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: center;
    margin: 10px 0px;
}

/* TP-6822 */
.userDetailContainer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    margin: 5px 10px;
}

/* .userDetailContainer .userDetaillabels {
    max-width: 28%;
} */

.userDetaillabels {
    flex: 26%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
    border: 1px solid #67627cd4;
    border-radius: 5px;
    margin: 2px 30px;
    padding: 5px;
    position: relative;
}

.userDetaillabels span {
    margin-left: 2px;
}

.newswitch {
    margin-bottom: 0px !important;
}

.lastblock {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
    margin: 5px 10px;
    padding-bottom: 5px;
}

.lastblock .userDetaillabels {
    justify-content: space-around;
}

.userInfoDiv {
    flex: 74%;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: flex-start;
    flex-wrap: wrap;
    margin-left: 2px;
}

.userInfo {
    flex: 50%;
}

.userDetailInfo {
    flex: 20%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.userDetailbtn button {
    position: static;
    padding: 5px 10px;
    border-radius: 8px;
}

.lastblock .userDetaillabels {
    border: 1px solid #67627cd4;
    height: 110px;
    padding: 5px 10px;
    border-radius: 8px;
}

.toolAccesslabel {
    width: 29%;
}

.toolAccessCl {
    width: 69%;
}

#select-user-role-type {
    width: 96%;
    margin-left: 5px;
}

.userDetaillabels button {
    border-radius: 5px;
    margin: 5px 2px;
    padding: 5px 8px;
}

.lastblock .userInfoDiv .userInfo {
    padding: 1px;
}

.panelDiv2 ul.react-tabs__tab-list {
    padding-bottom: 5px;
}

.panelDiv2 ul.react-tabs__tab-list li {
    margin: 0px 2px;
    border-radius: 5px !important;
}

.panelDiv2 .ftSrFl {
    word-break: break-all;
}

/* media queries starts here */

@media only screen and (max-width: 1024px) {
    .userDetailsselectBar button {
        font-size: 14px;
    }

    .userDetaillabels {
        font-size: 12px;
        margin: 2px 15px;
    }
}

@media only screen and (max-width: 911px) {
    .userDetaillabels {
        margin: 2px 5px;
    }
}

@media only screen and (max-width: 512px) {
    .userDetailContainer {
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
    }

    #select-user-role-type {
        width: 50%;
    }

    .userDetailContainer .userDetaillabels {
        width: 100%;
        max-width: 100%;
    }
}

@media only screen and (max-width: 768px) {
    .lastblock .userDetaillabels {
        height: 120px;
    }
}

@media only screen and (max-width: 425px) {
    .lastblock .userDetaillabels {
        height: 170px;
    }
}