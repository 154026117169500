#d3ChartField{
    width: 100%;
    height: 60vh;
}

.bar {
    fill: #71EEB8;
  }
  
  .max {
    fill: teal;
  }
  
  .min {
    fill: lightblue;
  }
  
  .axis-x path {
    display: none;
  }
  
  .axis text {
    font-weight: bold;
  }
  
  /* div.tooltip {	
      position: absolute;			
      text-align: center;	
      line-height: 28px !important;	
      width: 60px;					
      height: 28px;			
      padding: 2px;				
      font: 12px sans-serif;	
      font-weight: bold;	
      background: lightsteelblue;	
      border: 0px;		
      border-radius: 8px;			
      pointer-events: none;
      opacity: 0;	
  } */
  
  text.count {
    color: black;
    font-size: 0.7em;
  }


line#limit {
  stroke: #FED966;
  stroke-width: 3;
  stroke-dasharray: 3 6;
}

.grid path {
  stroke-width: 0;
}

.grid .tick line {
  stroke: #9FAAAE;
  stroke-opacity: 0.3;
}

text.divergence {
  font-size: 12px;
  fill: #2F4A6D;
}

text.value {
  font-size: 12px;
  color: black;
}

/* .title {
  text-anchor: middle;
  font-size: 22px;
  font-weight: 600;
  stroke: black;
} */

text.label {
  font-size: 12px;
  font-weight: 400;
  color: black;
}

text.source {
  font-size: 10px;
}
