
.mapViewIconCls{
  scale: 0.7;
  transform: scale(0.7);
  width: 70px;
  height: 70px;
  background: url("../care4dImg/map-view.png") no-repeat;
  display: inline-block;
}
.mapViewIconCls:hover{
  background: url("../care4dImg/map-view-hover.png") no-repeat;
}
.tableViewIconCls{
  scale: 0.7;
  transform: scale(0.7);
  width: 70px;
  height: 70px;
  background: url("../care4dImg/table-view.png") no-repeat;
}
.tableViewIconCls:hover{
  background: url("../care4dImg/table-view-hover.png") no-repeat;
}

/*DropdownTreeSelect css*/
.tag-item .search, .tag-remove, .radio-item{
  display: none !important;
}
.tag-item .tag{
  background-color: inherit !important;
  border: none !important;
  padding: 0px !important;
  border-radius: 0px !important;
  color: black;
}
.react-dropdown-tree-select .dropdown {
    left: 25% !important;
    z-index:1 !important;
    top: 2px !important;
}
.react-dropdown-tree-select .dropdown .dropdown-trigger{
  background: white !important;
  /* padding: 0px !important; TP-5488*/
  display: inline !important;
}
.react-dropdown-tree-select .dropdown .dropdown-content{
  overflow-y: auto !important;
  border-radius: 8px !important; /*TP-5488*/
  max-height: 60vh !important;
}
.node .toggle{
  padding-right: 2px !important;
  padding-left: 2px !important;
}

.diagnosticsTool{
  max-width: 75vw;
	max-height: 100vh;
	background-color: rgb(45, 56, 88);
	margin: auto;
}

.screen-share{
  cursor: pointer;
  border: none;
  border-radius: 25px;
  background-color: #23c97d;
  color: white;
  padding: 1px 10px 1px 10px;
  position: absolute;
  top: 0;
  right: 0;
}

.delete-jobs{
  cursor: pointer;
  border: none;
  border-radius: 25px;
  background-color: #23c97d;
  color: white;
  padding: 1px 10px 1px 10px;
  position: absolute;
  top: 20%;
  right: 0;
}

.copy-emails{
position: absolute;
  font-size: 20px !important;
  cursor: copy;
  z-index: 1;
  left: 32vw !important;/*TP-4164*/
  top: 2.4vh !important;
}

.users-table-title{
  font-size: 24px;
  text-align: center;
}

#user-participant-tooltip{
  visibility: hidden;
  /* width: 18vw; */
  font-family: Titillium Web;
  font-size: small;
  background-color: white;
  color: black;
  text-align: center;
  border-radius: 6px;
  padding: 5px;
  
  /* Position the tooltip */
  position: fixed;
  z-index: 1;
  /* top: -5px; */
  /* right: 0; */
}

/***********REACT TABS***********/

.react-tabs__tab {
  font-size: 16px;
}

.react-tabs__tab--selected, .react-tabs__tab:hover {
  background-color: #384571 !important;
  color: inherit !important;
  border-color: #384571 !important;
  border-radius: 10% !important;
}

.react-tabs__tab-list {
  border-bottom: 0px !important;
  margin: 0px !important;
}

.cell-text-area{
  width: 100%;
  min-height: 7rem;
  color: white;
  background-color: inherit;
  border-color: inherit;
  resize: none;
  border: 1px dotted #ccc;
  border-radius: 10px;
  font-size: 16px !important;
}

.logs-text-area{
  width: 100%;
} 

.comment-text-area{
  width: 100%;
  max-height: 3rem;
  color: white;
  background-color: inherit;
  resize: none;
  border: 0px;
}
.btn-log-search{
  vertical-align: initial;
  line-height: 1.0;
}
.display-show{
  display: block;
}
.display-hide{
  display: none;
}

.button-user {
  cursor: pointer;
  border: none;
  border-radius: 15px;
  color: black;
  padding: 1px 10px 1px 10px;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 0;
}

.first-login{  
  background-color: #fcd303;
  margin-top: 30px;  
}

.password-reset{
  background-color: #EA7050;  
}

#select-user-role-type {
  margin-left:10px;  /* TP-6476 */
  border-radius: 3px;  /* TP-6476 */
  text-align: end;
  text-align-last: center; 
  /* position: absolute; 
  top: 0%;
  right: 0%; */
}


.user-role {
  text-align: left;
  /* reset to left*/
}


#search-logs{
  float: right;
}

#react-tabs-3{
  margin-top: 10px;
}

.jobs-row-cols {
  display: flex;
}

.logs-row {
  display: flex;
}

#react-tabs-5{
  margin-top: 10px;
}


.globalLogsHuntCls{
  width: 40%;
  height: 37px;
  background-color: var(--main-text-color);
  border-radius: 20px;
  display:inline-block;
  margin: 0px 0px 0px 30px;
}


.mui-logs-InputClass .MuiAutocomplete-tagSizeSmall {
  margin: 6px !important;
  max-width: calc(100% - 4px);
}

.mui-logs-InputClass > input {
  width: 100%;
  padding: 12px 20px;
  margin: 8px 0;
  display: inline-block;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
}

.mui-logs-InputClass .MuiInputBase-input {
  margin-left: 15px !important;
  /* color: var(--main-text-color); */
}

.maxMonthlyMinDiv {
  position: absolute;
  right: 0;
}
.row-email{
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
  margin-top: 20px;
}
.text-success-time {
  color: #23c97d;
}

.text-error-time{
  color: crimson;
}
/* .muiInputClass > label {
  padding: 20px !important;
} */

/* .MuiInputLabel-marginDense {
  transform: translate(0, 10px) scale(1) !important;
}
.MuiInputLabel-animated {
  transition: color 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms,transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms !important;
} */
/************************************/
/*Medium devices (smartphone, 768px and up)*/
@media (max-width: 992px) {
  .copy-emails{
    font-size: 12px !important;
    top: 3vh !important;
  }
  .users-table-title{
    font-size: 16px;
  }
}
/*Only for Mozilla Firefox Browser*/
@-moz-document url-prefix() {
  .mapViewIconCls {
    scale:0.8;
    transform: scale(0.8);
  }
  .tableViewIconCls {
    scale:0.8;
    transform: scale(0.8);
  }
}


#input-group-logs.input-group{
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width:20%;
  margin-left:2% ;
}

.global-logs-row {
  display: flex;
  justify-content: center;
  width: 88%;
  margin: 0 auto;
  margin-left: 120px;
}


#global-start_date , #global-end_date {
  margin: 4px;
}

.global-logs-info{
  padding: 0px 238px 0px 310px;
}

#show-online-user-container {
  /* padding: 26px 0px 0px 0px; */
}
.disable-pointer-event{
  pointer-events: none;
}

.logs-select-cl{
  width: 26%;
  height: 37px;
  background-color: var(--main-text-color);
  border-radius: 20px;
  display:inline-block;
  /* margin: 0px 0px 0px 15px; */
  margin-left: 1%;
}

/*  TP-6485 */
.align-Lock-Icon{
  text-align: center;
  /* margin-left: 45px; */  
}

.disabled-lock-icon {
  text-align: center;
  margin-left: 45px;
  pointer-events: none;
  cursor: default;
  text-decoration: none;
}

.enable-all-lock-icon {
  text-align: center !important;
  border-radius: 25px !important;
  cursor: default;
  text-decoration: none;
  background-color: #2bf288 !important;
  border-color: #2bf288 !important;
}

.disabled-all-lock-icon {
  border-radius: 25px;
  text-align: center;
  pointer-events: none;
  cursor: default;
  text-decoration: none;
  background-color: gray !important;
  border-color: gray !important;
}

.group-admin-edit{
  color: #2AC878;
  cursor: pointer;
}

#radio-select-drop-down {
  margin: 0px 80px 22px 0px;
  border-radius: 8px !important; /*TP-5488*/
  padding: 10px 80px 22px 10px; /*TP-5488*/
}

#radio-select-drop-down_trigger {
  position: fixed;  
  border-radius: 8px !important; /*TP-5488*/
  /* left: 0;
  top: 0; */
}

#radio-select-drop-down_trigger a {
  margin-right: 10px;
  display: inline-block;
  padding: 10px 80px 22px 10px; /*TP-5488*/
}

#radio-select-drop-down_trigger ul {
  width: 100%;
  list-style: none;
  padding: 0;
  margin: 0 !important;
}

.nrNBW {
  display: flex;
  -webkit-box-align: stretch;
  align-items: stretch;
  min-height: 48px !important;
  width: 100%;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}

/*TP-4051*/
.disable-label {
  color: gray;
}

.lock-icon {
  font-size: 25px !important;
}

.lock-icon-table {
  font-size: 17px !important;
}

.globalUserHuntCls{
  width: 300px;
  border-radius: 8px;
  /* margin: auto; */
  margin: 0px 65px 0px 65px;
  background-color: var(--main-text-color);
  /* border-radius: 10px; */
}

/*TP-5488*/
.button-cls{
  margin: 20px !important;
}

.drop-down-class {
  height: 35px !important;
  border-radius: 8px;
  padding: 3px 0 3px 0; 
  margin: -9px 0 0 0;
  cursor: pointer;
  text-align: center !important;
}

.select-options {
  color: black;
  background-color: white;
}

/* TP-6393 */
.MuiBox-root #radio-select-drop-down_trigger{
  position: static !important;
  margin: 0px !important;
  padding: 4px 10px !important;
}

/* TP-6393 */
.MuiBox-root #radio-select-drop-down{
  margin: 0px;
  padding: 3px 10px !important;
}

/* TP-6425 */
.MuiBox-root .css-vok43g-MuiGrid-root{
word-wrap: break-word;
padding: 6px 0px !important;
}

/* TP-6406 */
.css-1rlym6w .css-1xcs9jg-MuiGrid-root{
  height: 40px !important;
}

/* TP-6393 */
.css-1rlym6w .css-11kmd87{
  height: 80px;
  padding: 6px 0px !important;
}

/* TP-6393 */
.css-1rlym6w .MuiPaper-elevation1{
  padding: 2px 0px !important;
}

/* TP-6485 */
@media (min-width: 426px) {

.usersTable .jDHQPG{
display: flex !important;
flex-direction: column;
}

.usersTable .jDHQPG .rdt_Table{
max-width: 100% !important;
}

.usersTable .jDHQPG .rdt_TableHead,
.usersTable .jDHQPG .rdt_TableBody{
  padding: 1px;
}

.usersTable .jDHQPG .rdt_TableHead .rdt_TableCol {
padding: 0px 5px !important;
}

.usersTable .jDHQPG .rdt_TableBody .rdt_TableRow .rdt_TableCell{
  padding: 0px 0px 0px 5px !important;
}

.usersTable .jDHQPG .rdt_TableHead .rdt_TableHeadRow,
.usersTable .jDHQPG .rdt_TableBody .rdt_TableRow {
display: flex;
flex-direction: row;
flex-wrap: nowrap;
justify-content: flex-start;
align-items: stretch;
}

.usersTable .jDHQPG .rdt_TableHead .rdt_TableHeadRow .rdt_TableCol {
flex: 1;
}

.usersTable .jDHQPG .rdt_TableBody .rdt_TableRow .emeeyG a{
  margin-left: 35px;
}

.usersTable .cXmCak .copySelection{
margin-top: 10px;
}

.usersTable .rdt_TableFooter{
  padding: 0px;
  max-width: 100% !important;
  }
 
}