.MuiInputLabel-root{
    color: #212529;
    text-align: center;
    text-align-last: center; 
    right: 0%;
    transform: translate(0, 15px) scale(1);
}

.MuiInputBase-fullWidth{
    height: 30px !important;
}

.MuiAutocomplete-inputRoot[class*="MuiInput-root"][class*="MuiInput-marginDense"] .MuiAutocomplete-input:first-child {
    padding: 8px 0 6px !important;
}

.muiInputClass :focus {
    opacity: 0;  
}

.MuiInputBase-fullWidth {
    height: 38px !important;
}

.MuiChip-sizeSmall {
    height: 24px;
    /* z-index: 10 !important; */
}

#globalLogsHuntCls .MuiInputLabel-marginDense{
    transform: translate(0, 14px) scale(1);
} 

#globalJobsHuntCls .MuiInputLabel-marginDense{
    transform: translate(0, 14px) scale(1);
} 

